import { Global, css } from "@emotion/react"

import manga from "../../assets/font/manga.otf";
import mangaBold from "../../assets/font/manga_bold.otf";


const Fonts = () => (
  <Global
    styles={css`
      body {
      @font-face {
        font-family: Manga;
        font-style: normal;
        font-weight: 400;
        src: url('${manga}') format('opentype');
      }

      @font-face {
        font-family: 'Manga Bold';
        font-style: bold;
        font-weight: 700;
        src: url('${mangaBold}') format('opentype');
      }


      body {
        background: red;
      }

      html {
      color: red !important;
      }
      `}
  />
)

export default Fonts
