import { Subject } from 'rxjs';
import _, { sample, clone } from 'underscore';


import gaaraBg from "../assets/chars/gaara.png";
import hyugaNejiBg from "../assets/chars/hyuga_neji.png";
import itachiBg from "../assets/chars/itachi.png";
import konanBg from "../assets/chars/konan.png";
import jiraiyaBg from "../assets/chars/jiraiya.png";
import narutoBg from "../assets/chars/naruto.png";
import minatoBg from "../assets/chars/minato.png";
import orochimaruBg from "../assets/chars/orochimaru.png";
import sakuraBg from "../assets/chars/sakura.png";
import sasukeBg from "../assets/chars/sasuke.png";
import madaraBG from "../assets/chars/madara.png";
import senjuTsunedeBg from "../assets/chars/senju_tsunede.png";


import avatarBgOrochimaru from "../assets/avatar_bg_orochimaru.png";
import avatarBgGaara from "../assets/avatar_bg_gaara.png";
import avatarBgJiraiya from "../assets/avatar_bg_jiraiya.png";
import avatarBgMinato from "../assets/avatar_bg_minato.png";
import avatarBgTsu from "../assets/avatar_bg_senju_tsunede.png";
import avatarBgMadara from "../assets/avatar_bg_madara.png";
import avatarBgHyunga from "../assets/avatar_bg_hyunga.png";
import avatarBgSakura from "../assets/avatar_bg_sakura.png";
import avatarBgNaruto from "../assets/avatar_bg_naruto.png";
import avatarBgKonan from "../assets/avatar_bg_konan.png";

const apiBase = "https://api.cryptonaruto.io";

const avatarBg = [ avatarBgNaruto, avatarBgKonan, avatarBgHyunga, avatarBgSakura ];


// 200 NTL token
const cardPrice = 200;

let cards = []
let balance = 0;

const characters = [
  {
    name: 'gaara',
    img: gaaraBg,
    bg: avatarBgGaara,
  },
  {
    name: 'hyuga_neji',
    img: hyugaNejiBg,
    bg: avatarBgHyunga,
  },
  {
    name: 'itachi',
    img: itachiBg,
    bg: avatarBgKonan,
  },
  {
    name: 'konan',
    img: konanBg,
    bg: avatarBgKonan,
  },

  {
    name: 'jiraiya',
    img: jiraiyaBg,
    bg: avatarBgJiraiya,
  },

  {
    name: 'madara',
    img: madaraBG,
    bg: avatarBgMadara,
  },

  {
    name: 'minato',
    img: minatoBg,
    bg: avatarBgMinato,
  },

  {
    name: 'naruto',
    img: narutoBg,
    bg: avatarBgNaruto,
  },

  {
    name: 'orochimaru',
    img: orochimaruBg,
    bg: avatarBgOrochimaru,
  },

  {
    name: 'sakura',
    img: sakuraBg,
    bg: avatarBgSakura,
  },

  {
    name: 'sasuke',
    img: sasukeBg,
    bg: avatarBgMadara,
  },

  {
    name: 'senju_tsunede',
    img: senjuTsunedeBg,
    bg: avatarBgTsu,
  },
]

const opponents = [
  {
    name: 'gaara',
    img: gaaraBg,
    blank: false,
    opponent: true,
    bg: avatarBgGaara,
  },
  {
    name: 'jiraiya',
    blank: false,
    img: jiraiyaBg,
    opponent: true,
    bg: avatarBgJiraiya,
  },
  {
    name: 'minato',
    img: minatoBg,
    blank: false,
    opponent: true,
    bg: avatarBgMinato,
  },
  {
    name: 'orochimaru',
    img: orochimaruBg,
    blank: false,
    opponent: true,
    bg: avatarBgOrochimaru,
  },
  {
    name: 'senju_tsunede',
    img: senjuTsunedeBg,
    opponent: true,
    blank: false,
    bg: avatarBgTsu,
  },
]

export const addCard = async (new_card) => {
  console.log("add card", new_card);
  const resp = await fetch(apiBase + `/buycard`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
    },
    body: JSON.stringify({
      id: myID(),
      idx: new_card.idx,
    })
    //credentials: "include",
  })

  const json = await resp.json();

  console.log('get card data', json)

  cards = json.data.cards.map((elem, idx) => {
    const character = _.find(characters, c => c.name == elem.name);
    return {
      ...elem,
      ...character,
      idx,
    }
  })

  cardsSubject.next(cards)
}

export const getCards = async () => {
  //if (cards.length == 0) {
  //  for (let i = 0; i<10; i++) {
  //    cards.push({
  //      idx: i,
  //      blank: i % 2 == 0,
  //      name: characters[i].name,
  //      img: characters[i].img,
  //      bg: characters[i].bg || avatarBg[Math.floor(Math.random() * avatarBg.length)],
  //      charka: 16,
  //      rare: Math.floor(Math.random() * 6),
  //    })
  //  }
  //}

  const resp = await fetch(apiBase + `/cards/?id=` + encodeURIComponent(myID()), {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
    },
    //credentials: "include",
  })

  const json = await resp.json();

  console.log('get card data', json)

  cards = json.data.cards.map((elem, idx) => {
    const character = _.find(characters, c => c.name == elem.name);
    return {
      ...elem,
      ...character,
      idx,
    }
  })

  cardsSubject.next(cards)

  return cards;
}

export const myCards = () => {
  return _.filter(cards, (e) => !e.blank);
}

export const getOpponents = () => {
  return clone(sample(opponents, 4)).map((e, idx) => {
    e.level = idx + 1;
    switch (e.level) {
      case 1:
        e.reward = Number((cardPrice / 10 / 8).toFixed(2));
        break;
       case 2:
       e.reward = Number((cardPrice / 9 / 8).toFixed(2));
        break;
       case 3:
       e.reward = Number((cardPrice / 8 / 8).toFixed(2));
        break;
       case 4:
        e.reward = Number((cardPrice / 7 / 8).toFixed(2));
        break;
    }

    return e;
  })
}

export const addBalance = (amt) => {
  balance += amt;

  return balance;
}

export const getBalance = () => {
  return balance;
}

export const reduceCharka = (p, amt) => {
  cards = cards.map(c => {
    if (c.name == p.name) {
      c.charka = c.charka + amt
    }

    return c
  })

  cardsSubject.next(cards)
}

// Subscriber
export const cardsSubject = new Subject();

// Set wallet id
export const setMyID = (account_id) => {
  window.localStorage.setItem("account_id", account_id);
  userSubject.next(account_id);
}

export const myID = () => {
  return window.localStorage.getItem("account_id");
}

export const userSubject = new Subject();


export const hasConnectedWallet = () => {
  return myID() !== null;
}
