import React, { useState, useEffect } from 'react';

import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Center,
  Code,
  Grid,
  theme,
  SimpleGrid,
  GridItem,
  Button,
  Image,
  Flex, Spacer,

  Alert, AlertIcon, AlertTitle,


  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,

  FormControl,
  FormLabel,
  FormHelperText,
  NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper

} from '@chakra-ui/react';

import { css, cx } from '@emotion/css'
import styled from '@emotion/styled'

import { BN } from 'bn.js';

const Span = styled.span`
  display: block;
  font-size: 4.5rem;
`

const LiTimer = styled.li`
            display: inline-block;
            font-size: 1.5em;
            list-style-type: none;
            padding: 1em;
            text-transform: uppercase;
`

// 1 BNB = 10000 tokens
const rate = 10000;
const openAt = 1635177600000;

const Countdown = ({onDone,...rest}) => {
  const second = 1000,
    minute = second * 60,
    hour = minute * 60,
    day = hour * 24;

  const countDown = new Date(openAt).getTime();

  const [distance, setDistance] = useState(countDown - new Date().getTime());

  useEffect(() => {
    const x = setInterval(()=> {
      const now = new Date().getTime();
      if (countDown < now) {
        onDone(true);
      }
      setDistance(countDown - now);
    }, 1000);

    return () => {
      clearInterval(x)
    }
  }, [distance])

  return (
    <div class="container">
      <h1 id="headline" className={css`
        font-weight: normal;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
      `}>Countdown to Naruto Legend ($NTL) presale</h1>
    <div id="countdown">
      <ul>
        <LiTimer>
          <Span>{Math.floor(distance / (day))}</Span>days
        </LiTimer>
        <LiTimer>
          <Span>
            {Math.floor((distance % (day)) / (hour))}
            </Span>Hours
          </LiTimer>

          <LiTimer><Span id="minutes">{Math.floor((distance % (hour)) / (minute))}</Span>Minutes</LiTimer>
          <LiTimer><Span id="seconds">{Math.floor((distance % (minute)) / (second))}</Span>Seconds</LiTimer>
        </ul>
      </div>
    </div>
  )
}

const buy = async (amount, setPending, setStatus) => {
  if (typeof window.ethereum == 'undefined') {
    alert('Please install MetaMask!');
    return
  }

  const network = window.ethereum.networkVersion;
  if (network != 56 && network != 97) {
    alert('Please switch your metamask to Binance Smart Chain network');
    return
  }

  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  if (accounts.length <= 0) {
    alert("Please connect your wallet");
    return
  }

  try {
    let hexAmount = new BN((parseFloat(amount) * 1e18).toString(), 10);

    setPending(true);
    const transactions = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [
        { from: accounts[0],
          to: process.env.REACT_APP_WALLET_ADDRESS || '0x556F3e77E6362910459DC6f4a6e49ACC871b3d9A',
          value: hexAmount.toString(16),
        },
      ],
    })
    setStatus("Waiting for transaction");
    const txr = await transactions.wait();
    alert("Your transaction is confirmed. We will distributed tokens to your wallet later");

  } catch (error) {
    setPending(false);
  }

}

const Presale = ({demo, ...rest}) => {
  const [pending, setPending] = useState(false);

  const [value, setValue] = useState(1.0);
  const [status, setStatus] = useState("");

  const countDown = new Date(openAt).getTime();
  const isOpen = countDown < new Date().getTime();
  const [done, setDone] = useState(isOpen);

  return (
    <Box mt={100} background="white" padding={5} colorScheme="blue" width="80%">
      { (!done) &&
      <Center>
        <Countdown onDone={() => setDone(true)} />
      </Center>
      }

      { (demo || done) && (
        <Center>
          <VStack>
            <Alert status="success" width="80%">
              <AlertIcon />
              Make sure to switch your MetaMask to Binance Smart Chain and connect to the right account you entered into the whitelist form.
            </Alert>
          </VStack>

          <FormControl isRequired mt={10}>
            <FormLabel>
              <span className={css`font-size: 1.2em`}>
                Amount(in BNB):
              </span>
            </FormLabel>

            <NumberInput min={0.1} width={200} mr={15} precision={2} step={0.2}
              onChange={(valueString) => {
                if (valueString !== "") {
                  setValue(valueString)
                }
              }}
              value={value}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>

            <FormLabel>
              {value} BNB = {parseFloat(value) * rate} NTL
            </FormLabel>

            { pending ?  <span><Spinner />{status}</span> : <Button colorScheme="blue" variant="solid" isRound size={"lg"} onClick={evt => buy(value, setPending, setStatus)}>Buy</Button> }
          </FormControl>
        </Center>
      )}
    </Box>
  )
}

export default Presale
