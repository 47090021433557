import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  SimpleGrid,
  Button,

  Alert, AlertIcon, AlertTitle, AlertDescription
} from '@chakra-ui/react';

import PlayerCard from '../components/shared/player_card';

import * as accountStore from '../stores/account';

import { Redirect, useHistory, useLocation } from 'react-router-dom';


const Login = () => {
  let [ready, setReady] = useState(false);
  let history = useHistory();
  let location = useLocation();

  let { from } = location.state || { from: { pathname: "/" } };

  const login = async (e) => {
    const ver = window.ethereum.networkVersion;

    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
    if (accounts.length >= 0) {
      accountStore.setMyID(accounts[0]);
      setReady(true);
      history.replace(from);
      return
    }
  }

  let hasMetamask = false;
  if (typeof window.ethereum !== 'undefined') {
    hasMetamask = window.ethereum.isMetaMask;
  }

  return (
    <Box mt={100}>
      { hasMetamask ?  <Button onClick={login}>Connect wallet to play</Button> :
          (
            <Alert status="error">
              <AlertIcon />
              <AlertTitle mr={2}>You need metamask wallet to play this game</AlertTitle>
              <AlertDescription><Link to="https://metamask.io/">Click here to install metamask extension</Link></AlertDescription>
            </Alert>
          )
      }
    </Box>
  )
}

export default Login
