import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  //HStack,
  Grid,
  theme,
  Center,
  Image
} from '@chakra-ui/react';

//import theme from "./components/shared/theme";
import Fonts from "./components/shared/fonts";

import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';


//import Home from './pages/home';
import MarketPlace from './pages/marketplace';
import ComingSoon from './pages/coming_soon';
import Boss from './pages/boss';
import Cards from './pages/cards';
import Battle from './pages/battle';
import Home from './pages/home';
import Login from './pages/login';
import Presale from './pages/presale';

import AppHeader from './components/shared/header';
import Sound from './components/shared/sound';

import {
  ProvideAuth,
  PrivateRoute,
  AuthButton
} from './components/shared/auth';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from 'react-router-dom';


import BgMain1 from './assets/bg_main1.png';
import LogoImg from './assets/logo.png';
import BattleButtonImg from './assets/battle_button.png';


function App() {
  //<ColorModeSwitcher justifySelf='flex-end' />
  return (
    <ProvideAuth>
      <Router>
        <ChakraProvider theme={theme}>
          <Fonts />
          <Box textAlign='center' fontSize='xl'
            backgroundImage={BgMain1}
            backgroundPosition='top left'
            backgroundRepeat='no-repeat'
            pt={45}>

          <Box pos='fixed' w={400} h={135} top={10} left={10}>
            <RouterLink to='/'>
              <Image src={LogoImg} alt='Battle' />
            </RouterLink>
          </Box>

          <Box pos='fixed' w={150} h={150} bottom={0} right={10}>
            <RouterLink to='/battle'>
              <Image src={BattleButtonImg} alt='Battle' />
            </RouterLink>
          </Box>

          <Box className="appheader" pl={415}>
            <AppHeader/>
          </Box>

          <Grid minH='87vh' p={3}>
            <VStack spacing={8}>

              <Switch>

                <Router path='/login'>
                  <Login />
                </Router>

                <Router path='/upgrade'>
                  <ComingSoon />
                </Router>

                <Router path='/Boss'>
                  <Boss />
                </Router>

                <Router path='/marketplace'>
                  <MarketPlace />
                </Router>

                <PrivateRoute path='/battle'>
                  <Battle />
                </PrivateRoute>

                <Router path='/presale'>
                  <Presale />
                </Router>

                <Router path='/presaledemo'>
                  <Presale demo={true}/>
                </Router>

                <PrivateRoute path='/'>
                  <Cards />
                </PrivateRoute>
              </Switch>

            </VStack>
          </Grid>
        </Box>
      </ChakraProvider>
    </Router>
  </ProvideAuth>
  );
}

export default App;
