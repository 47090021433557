import React from 'react';

import {
  Box,
  VStack,
  Image,
  Text,
} from '@chakra-ui/react';

import cardImg from "../../assets/frame_card_bg.png";
import cardImg2 from "../../assets/char_frame_bg.png";
import smallFrame from "../../assets/char_frame_bg_small.png";
import largeFrame from "../../assets/char_frame_bg_large.png";


const sizeMap = {
  large: {
    w: 264,
    h: 398,

    avatar: {
      width: 200,
      height: 200,
      top: 60,
      left: 25,
    },

    frame: largeFrame,
    mt: 265,
    titleSize: 34,
    subTitleSize: 18,
    subTitlePl: 10,
    subTitleMt: 0,
  },

  medium: {
    w: 185,
    h: 277,

    avatar: {
      width: 150,
      height: 150,
      top: 30,
      left: 14,
    },

    frame: cardImg2,
    mt: 185,
    titleSize: 24,
    subTitleSize: 15,
    subTitlePl: 8,
    subTitleMt: 0,
  },

  small: {
    w: 186,
    h: 280,

    avatar: {
      width: 150,
      height: 150,
      top: 30,
      left: 14,
    },

    frame: smallFrame,
    mt: 187,
    titleSize: 22,
    subTitleSize: 15,
    subTitlePl: 8,
    subTitleMt: 2,
  },
}

const PlayerCard = ({children, card, sizeVariant, idx, ...props}) => {
  const size = sizeMap[sizeVariant];

  return (
    <Box
      backgroundImage={card.blank ? cardImg : card.bg}
      backgroundPosition="top center"
      backgroundSize={`${size.w}px ${size.h}px`}
      backgroundRepeat="no-repeat"
      height={size.h}
      width={size.w}
      {...props}
      position="relative">
      { card.blank ?  children : (
        <>
        <Image
          src={card.img}
          position="absolute"
          style={{...size.avatar}}
        />
        <VStack
          position="absolute"
          width='100%' height='100%'
          backgroundImage={size.frame}
          backgroundPosition="top center"
          backgroundRepeat="no-repeat">

          <Text
            mt={size.mt}
            bgClip="text"
            fontWeight="normal"
            color="white"
            fontSize={size.titleSize}
            fontFamily='Manga Bold'>{card.name}</Text>

          { !card.opponent ? <VStack align="stretch" w="100%" pl={size.subTitlePl} style={{marginTop: size.subTitleMt}}>
              <Text
                mt={0}
                align="left"
                color="white"
                fontSize={size.subTitleSize}
                fontFamily="Manga Bold">
                Rare: {card.rare}
              </Text>
              <Text
                style={{marginTop: 0 }}
                mt={0}
                align="left"
                color="white"
                fontSize={size.subTitleSize}
                fontFamily="Manga Bold">
                Charka: {card.charka}
              </Text>
            </VStack> :
            <VStack align="stretch" w="100%" pl={size.subTitlePl} style={{marginTop: size.subTitleMt}}>
              <Text
                mt={0}
                align="left"
                color="white"
                fontSize={size.subTitleSize}
                fontFamily="Manga Bold">
                Reward: {card.reward}
              </Text>
              </VStack>
          }

            </VStack>
        </>
      )}
    </Box>
  )
}

export default PlayerCard
