import React, { useState, useEffect } from 'react';

import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Center,
  Code,
  Grid,
  theme,
  SimpleGrid,
  GridItem,
  Button,
  Image,
  Flex, Spacer,

  Alert, AlertIcon, AlertTitle,


  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure

} from '@chakra-ui/react';

import PlayerCard from '../components/shared/player_card';

import * as accountStore from '../stores/account';

import fightButton from '../assets/fight_button.png';
import fightButtonActive from '../assets/fight_button_active.png';

import arrowUpButton from '../assets/arrow_up_button.png';
import arrowDownButton from '../assets/arrow_down_button.png';

import { css } from '@emotion/react'
import FightGif from '../components/gif'

const fight = (player, opponent) => {
  if (player.chakra <= 0) {
    alert("This card has no more charka too fight. Come back tomorrow or use another card");
    return;
  }

  console.log("player", player, "fight with", opponent)

  let result = false;

  switch (opponent.level) {
    case 1:
      // so easy 90% win
      result = Math.random() * 10 < 9
      break;
    case 2:
      // easy 70% win
      result = Math.random() * 10 < 7
      break;
    case 3:
      // easy 50% win
      result = Math.random() * 10 < 9
      break;
    case 4:
      // easy 30% win
      result = Math.random() * 10 < 9
      break;
  }

  accountStore.reduceCharka(player, -2);

  return result ? "win": "lose"
}


const Fight = ({
  pickedCard, opponent,
  onClose
}) => {
  const [result, setResult] = useState(null);
  console.log("render fight");
  const fightAgain = () => {
    setResult(null);

    setTimeout(()=> {
      const tr = fight(pickedCard, opponent);
      setResult(tr === 'win');
    }, 3000)

  }

  useEffect(() => {
    setTimeout(()=> {
      fight(pickedCard, opponent);
      setResult(true);
    }, 3000)
  }, [])

  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={true} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
              VS. {opponent.name}. {pickedCard.charka} charka left
          </ModalHeader>

          <ModalBody>
            { result ? null : <FightGif /> }

            { result === true &&
              <Text fontWeight="bold" mb="1rem">
                You win. you gain {opponent.reward} tokens.
              </Text>
            }

            { result === false &&
            <Text fontWeight="bold" mb="1rem">
              You lose.
            </Text>
            }
          </ModalBody>

            { result && (<ModalFooter>
          
              <Button colorScheme="blue" mr={3} onClick={onClose} onClick={fightAgain}>
                Fight again
              </Button>
              <Button variant="ghost" onClick={onClose}>I'm done</Button>
          </ModalFooter>
            )}
        </ModalContent>
      </Modal>
    </>
  )
}

const Battle = () => {
  const [cards, setMyCards]  = useState(accountStore.myCards());
  console.log("my card", cards);
  const [cardIdx, setCardIdx] = useState(0);
  const [opponents, setOpponents] = useState(accountStore.getOpponents());

  const [pickedCard, setPickedCard] = useState(cards[0]);
  const [pickedOpponent, setPickedOpponent] = useState(null);

  const [fighting, setFighting] = useState(false);

  accountStore.cardsSubject.subscribe({
    next: (v) => setMyCards(accountStore.myCards)
  })

  console.log("render battle");

  const move = (inc) => {
    if (cardIdx + inc < 0) {
      setCardIdx(0);

      return
    }

    if (cardIdx + inc >= (cards.length - 2)) {
      setCardIdx(cards.length - 2);
      return
    }

    setCardIdx(cardIdx + inc);
  }

  const pick = (c) => {
    setPickedCard(c);
    setOpponents(accountStore.getOpponents());
  }

  return (
    <SimpleGrid w='100%' columns={2} gap={240}>
      <Box>
        <Flex>
          { fighting ? <Fight pickedCard={pickedCard} opponent={pickedOpponent} onClose={() => setFighting(false)} /> : null }

          <Spacer />
          <Box w={500}>
            { cards.length <= 0 ?
              <Alert status="error" mt={300}>
                <AlertIcon />
                <AlertTitle mr={2}>Please buy card to get started</AlertTitle>
              </Alert> :
              <SimpleGrid columns={2}>
                <Box w={186}>
                  <Center>
                    <Image
                      src={arrowUpButton} onClick={(e) => move(-1)} cursor="pointer" align="center"
                      css={css`
                      &:hover {
                        box-shadow: 2px 2px 20px 20px #0ff;
                      }
                      `
                      }/>
                  </Center>

                  <PlayerCard cursor='pointer' onClick={e => pick(cards[cardIdx])} key={'p1' + cardIdx} card={cards[cardIdx]} sizeVariant='small'   mt={5}></PlayerCard>
                  <PlayerCard cursor='pointer' onClick={e => pick(cards[cardIdx+1])}  key={'p2' + cardIdx} card={cards[cardIdx+1]} sizeVariant='small' mt={10}></PlayerCard>

                  <Center>
                    <Image src={arrowDownButton} onClick={(e) => move(1)} cursor="pointer" mt={5} />
                  </Center>
                </Box>
                <Box w={264}>
                  <PlayerCard card={pickedCard} sizeVariant='large' mt={200}>
                  </PlayerCard>
                </Box>
              </SimpleGrid>
            }
          </Box>
        </Flex>
      </Box>
      <Box className="opponents">
        <SimpleGrid columns={4} gap={10} style={{marginTop: '52px'}}>
          {opponents.map((opponent, idx) => {
            return (
              <>
              <Box key={'c' +  opponent.idx} mt={5} w={186}>
                <PlayerCard card={opponent} sizeVariant='small'>
                </PlayerCard>
              </Box>
              <Box key={'p' + opponent.idx} w={108}>
                <Image
                src={fightButton}
                mt={116}
                cursor="pointer"
                onMouseOver={(e) => e.currentTarget.src = fightButtonActive }
                onMouseOut={(e) => e.currentTarget.src = fightButton }
                onClick={(e) => {
                  if (pickedCard.charka <= 0) {
                    alert("Not enough charka on this card. Pick another");
                    return
                  }
                  setPickedOpponent(opponent);
                  setFighting(true);
                }}/>
              </Box>
              </>
            )
          })}
        </SimpleGrid>
      </Box>
    </SimpleGrid>
  )
}

export default Battle
