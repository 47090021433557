import A from '../assets/warr/1.gif';
import B from '../assets/warr/2.gif';
import C from '../assets/warr/3.gif';
import D from '../assets/warr/4.gif';
import E from '../assets/warr/5.gif';
import F from '../assets/warr/6.gif';

import {
  Box,
  Image
} from "@chakra-ui/react"
import _ from 'underscore';

const imgs = [A, B, C, D, E, F]

const FightGif = () => {
  return (
    <Box boxSize="sm">
      <Image src={_.sample(imgs)} alt="Fighting..." />
    </Box>
  )
}
export default FightGif
