import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  Code,
  Grid,
  theme,
  SimpleGrid,
  Button,
  Spinner
} from '@chakra-ui/react';

import PlayerCard from '../components/shared/player_card';

import * as accountStore from '../stores/account';


const buyCard = (card, setPhase) => {
  return async (evt) => {
    setPhase('loading');

    console.log("buy card", card);
    card.blank = false;
    await accountStore.addCard(card);
    setPhase('ready');
  }
};

const Cards = () => {
  const [cards, setCards]  = useState(null);
  const [phase, setPhase] = useState('ready');

  console.log("subscribe to card changes")
  useEffect(async () => {
    const s1 = accountStore.cardsSubject.subscribe(v => {
      console.log("got", v);
      setCards(v);
    })

    await accountStore.getCards();

    return () => {
      console.log("Unsubscribe to card changes")
      s1.unsubscribe();
    }
  }, [])

  return cards === null ?
    <Box><Spinner /></Box> :
    (
      <Box>
      <SimpleGrid columns={5} spacing={10}>
        {cards.map((e, idx) => {
          return (
            <PlayerCard key={e.idx} card={e} onClick={buyCard(e, setPhase)} sizeVariant='medium'>
              <Box>
                <VStack>
                  { phase === 'ready' ?
                      <Button mt={130}> Click to buy </Button>
                      :
                      <Spinner />}
                </VStack>

              </Box>
            </PlayerCard>
          )
        })}

      </SimpleGrid>
    </Box>
  )
}

export default Cards
