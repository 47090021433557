import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  Link,
  VStack,
  HStack,
  Code,
  Grid,
  theme,
  Center,
  Image
} from '@chakra-ui/react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink
} from "react-router-dom";

import marketplaceImg from '../../assets/menu/marketplace.png';
import cardImg from '../../assets/menu/card.png';
import upgradeImg from '../../assets/menu/upgrade.png';
import bossImg from '../../assets/menu/boss.png';

const AppHeader = () => {
  return (
    <HStack spacing="24px">
      <Box >
        <RouterLink to="/upgrade">
          <Image src={upgradeImg} htmlWidth={329} htmlHeight={67} />
        </RouterLink>
      </Box>
      <Box >
        <RouterLink to="/cards">
          <Image src={cardImg} htmlWidth={327} htmlHeight={67} />
        </RouterLink>
      </Box>
      <Box >
        <RouterLink to="/boss">
          <Image src={bossImg} htmlWidth={315} htmlHeight={56} />
        </RouterLink>
      </Box>
      <Box >
        <RouterLink to="/marketplace">
          <Image src={marketplaceImg} htmlWidth={317} htmlHeight={56} />
        </RouterLink>
      </Box>
    </HStack>
  )
}

export default AppHeader
